/* General container styling */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, rgba(13, 172, 32, 0.8), rgba(9, 52, 13, 0.8));
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* Blurred background */
.loading-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    /* Use the same gradient as the container */
    filter: blur(10px);
    /* Adjust the blur intensity */
    z-index: -1;
    /* Keep the blur behind the content */
}

/* Animated cube styling */
.cube {
    position: relative;
    width: 150px;
    /* Increased width */
    height: 150px;
    /* Increased height */
    transform-style: preserve-3d;
    animation: rotate 2s infinite ease-in-out;
    z-index: 1;
}

.cube .face {
    position: absolute;
    width: 150px;
    /* Increased width */
    height: 150px;
    /* Increased height */
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #0dac20;
    /* Updated to match the green color */
    box-shadow: 0 0 20px rgba(13, 172, 32, 0.5);
    /* Glowing green effect */
}

/* Face positions to create a 3D effect */
.face1 {
    transform: rotateY(0deg) translateZ(100px);
}

/* Adjusted translateZ */
.face2 {
    transform: rotateY(90deg) translateZ(100px);
}

.face3 {
    transform: rotateY(180deg) translateZ(100px);
}

.face4 {
    transform: rotateY(-90deg) translateZ(100px);
}

.face5 {
    transform: rotateX(90deg) translateZ(100px);
}

.face6 {
    transform: rotateX(-90deg) translateZ(100px);
}

/* Rotation animation */
@keyframes rotate {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: rotateX(180deg) rotateY(180deg);
    }

    100% {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

/* Loading text styling */
.loading-text {
    margin-top: 20px;
    font-size: 3.2rem;
    letter-spacing: 0.1rem;
    animation: fadeIn 2s ease-in-out infinite;
    z-index: 1;
}

@keyframes fadeIn {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}