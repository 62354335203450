:root {
    --main-color: #0dac20;
    --sub-main-color: #0dac20;

    --main-color-200: #0dac20;
    --main-color-300: #0dac20;
    --main-color-400: #0dac20;
    --main-color-500: #0dac20;
    --main-color-600: #0dac20;
    --main-color-700: #0dac20;
    --main-color-800: #0dac20;
    --main-color-900: #0dac20;

    --sub-main-color-200: #0dac20;
    --sub-main-color-300: #0dac20;
    --sub-main-color-400: #0dac20;
    --sub-main-color-500: #0dac20;
    --sub-main-color-600: #0dac20;
    --sub-main-color-700: #0dac20;
    --sub-main-color-800: #0dac20;
    --sub-main-color-900: #0dac20;



    --green-color: #89B72B;
    --orange-color: #F8B01D;
    --black-color: #000000;
    --red-color: #ff6347;
    --white-color: #ffffff;
}

.btn-yellow-500 {
    background-color: var(--main-color-500);
}

.btn-yellow-default {
    background-color: var(--main-color-500);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-default:hover {
    background-color: var(--main-color-600);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-active {
    background-color: var(--main-color-700);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-disable {
    background-color: var(--main-color-200);
    color: var(--main-color-400);
    margin: 0px 15px;
}


.btn-red-default {
    background-color: var(--sub-main-color-500);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-default:hover {
    background-color: var(--sub-main-color-600);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-active {
    background-color: var(--sub-main-color-700);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-disable {
    background-color: var(--sub-main-color-200);
    color: var(--sub-main-color-400);
    margin: 0px 15px;
}

.title-content-common {
    font-size: 25px;
    padding-bottom: 16px;
    color: var(--sub-main-color-800);
}

.main-card-box {
    border-top: 3px solid var(--sub-main-color-400);
}

.swal2-backdrop-show {
    z-index: 9999 !important;
}

/* Countreport */
.dashboard-count-box {
    border-radius: 10px;
}

.dashboard-count-box .icon {
    width: 40px;
    height: 40px;
}

.dashboard-count-box h5 {
    font-size: 16px;
}

/* Countreport */